import React from "react";
import { Flex } from "../style/Grid";
import { Image } from "../style/Image";
import { Heading } from "../style/Heading";
import { Text } from "../style/Text";



export const BlockOfferCard = ({
    headingMb,
    heading,
    bannerImage,
    href,


    gasText,
    electricityText
}) =>
{




    return (
        <Flex
            flexDirection="column"
            as="a"
            href={href}
            flexBasis={["100%", null, 0]}
            flexShrink={[0, null, 1]}
            flexGrow={1}
            p={20}
            backgroundColor={"white.100"}
            className="no-underline"
        >
            <Image src={bannerImage} loading="lazy" margin={"0 auto"} width={"100%"}></Image>
            <Heading as="h3" mt={[25]} fontSize={["24px", "32px"]} fontWeight="700" mb={headingMb} color="professionalGray" textDecoration="none">
                {heading}
            </Heading>
            <Text color="green.dark" fontSize={"18px"} lineHeight={"24px"} fontWeight={700} textDecoration="none">
                <Image src={require("../images/gas.svg").default} loading="lazy" height={"24px"} mr={"5px"} position={"relative"} verticalAlign={"text-bottom"} />
                {gasText}
            </Text>
            <Text color="green.dark" fontSize={"18px"} lineHeight={"24px"} fontWeight={700} textDecoration="none">
                <Image src={require("../images/electricity.svg").default} loading="lazy" height={"24px"} mr={"5px"} position={"relative"} verticalAlign={"text-bottom"} />
                {electricityText}
            </Text>
        </Flex>
    )

}
import React from "react";
import { Container, Flex } from "../style/Grid";
import { WhyMndWrapper } from "./WhyMndWrapper";
import { MND_LINKS } from "../constants";
import { Heading } from "../style/Heading";
import { Text } from "../style/Text";
import { Image } from "../style/Image";
import { BlockOfferCard } from "./blockOfferCard";

const CompaniesOffer = ({ title, ...props }) =>
{
    return (
        <WhyMndWrapper backgroundColor={'resourceGreenLighter'} {...props}>
            <Container px={["20px", "12px"]}>
                <Flex flexDirection="column" flexGrow="1" flexBasis={["100%", "1%"]}>
                    <Heading as="h2" fontSize={["32px", "42px"]} lineHeight="52px" mt="15px" mb="15px" fontWeight="700" color="exploratoryGreen">
                        {title}
                    </Heading>
                    <Text fontSize="20px" lineHeight="28px" color="exploratoryGreen" fontWeight="700" pb="7px" mb="20px">Zvolte vaši spotřebu:</Text>
                </Flex>
                <Flex flex={1} width={1} gap={"25px"} justifyContent="space-between" flexWrap="wrap">
                    <BlockOfferCard
                        headingMb="20px"
                        heading="Menší odběratel"
                        bannerImage={require("../images/mensi-odberatel.png").default}
                        href={MND_LINKS.companies_and_businesses_smaller_customer}

                        gasText="Plyn do 63 MWh/rok"
                        electricityText="Elektřina do 30 MWh/rok"
                    />

                    <BlockOfferCard
                        headingMb="20px"
                        heading="Větší odběratel"
                        bannerImage={require("../images/vetsi-odberatel.png").default}
                        href={MND_LINKS.companies_and_businesses_bigger_customer}

                        gasText="Plyn nad 63 MWh/rok"
                        electricityText="Elektřina nad 30 MWh/rok"
                    />
                </Flex>
            </Container >
        </WhyMndWrapper >
    );
};

CompaniesOffer.defaultProps = {
    title: "Nabídka energií pro firemní zákazníky",
};

export default CompaniesOffer;

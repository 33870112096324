import React from "react";
import { Helmet } from "react-helmet";
import WhyMND from "../../components/whyMND";
import CompaniesOffer from "../../components/companiesOffer";
import MainLayout from "../../componentsTailwind/MainLayout";
import config from "../../configs";

export default ({}) =>
{
  const SEO = {
    title: "MND | Energie - plyn a elektřina za výhodné ceny",
    keywords: "dodavatel plynu, dodavatel elektřiny, dodavatel energií, srovnání cen plynu, srovnání cen elektřiny, cena plynu, cena elektřiny, změna dodavatele",
    description: "Získejte výhodnou cenu energií od stabilního a férového dodavatele elektřiny a plynu. Změna dodavatele energií jde s MND jednoduše online.",
  }
  return (
    <MainLayout
      headerConfig={{
        topPanel: true,
        mainPanel: true,
      }}
    >
      <Helmet>
        <title>{SEO.title}</title>
        <meta name="keywords" content={SEO.keywords} />
        <meta name="description" content={SEO.description} />
        <meta name="og:description" content={SEO.description} />
        <meta property="og:image" content={`${config.serverUrl}/images/og-image-hp.png`} />
        <script>{`
          var gaAdditionalPageData = JSON.stringify({ type: 'Homepage', section: 'Gas | Electricity' });
        `}</script>
        <link rel="canonical" href="https://www.mnd.cz/"></link>
      </Helmet>
      <div>
        <CompaniesOffer />
      </div>
      <div>
        <WhyMND bg={"white.100"} />
      </div>
    </MainLayout>
  );
};